<template>
    <div class="loyalty-share-points">
        <div class="points-content-inner">
            <div class="points-up-content">
                <h2 class="header-title luxury">{{ item.title }}</h2>
                <div class="content" v-html="item.content"></div>
            </div>
            <div class="image-container">
                <picture class="picture small-image-picture"
                         data-scroll
                         data-scroll-speed=".5"
                >
                    <source
                        media="(max-width:767px)"
                        :srcset="item.mobile1"
                    />
                    <source
                        media="(max-width:1023px)"
                        :srcset="item.tablet1"
                    />
                    <img class="small-image"
                         :src="item.desktop1"
                    />
                </picture>
                <picture
                    data-scroll
                    data-scroll-speed="1"
                >
                    <source
                        media="(max-width:767px)"
                        :srcset="item.mobile2"
                    />
                    <source
                        media="(max-width:1023px)"
                        :srcset="item.tablet2"
                    />
                    <img class="main-image"
                         :src="item.desktop2"
                    />
                </picture>
            </div>
        </div>
        <div class="background-container"></div>
    </div>
</template>

<script setup>
import {computed} from "vue";


const props = defineProps({
    contentData: {
        type: Object,
    },
});
let title = computed(() => props.contentData?.conf?.header?.title)
let item = computed(() => {
    let item = props.contentData.data?.list?.[0]
    return {
        ...item,
        mobile1: item?.images?.[0]?.devices?.mobile,
        desktop1: item?.images?.[0]?.devices?.desktop,
        tablet1: item?.images?.[0]?.devices?.tablet,
        mobile2: item?.images?.[1]?.devices?.mobile,
        desktop2: item?.images?.[1]?.devices?.desktop,
        tablet2: item?.images?.[1]?.devices?.tablet,
    }
})

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.loyalty-share-points {
    position: relative;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .45s $ease;
    padding-top: 220px;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding-top: 200px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        padding-top: 170px;
    }
    @media only screen and (max-width: 1023px) {
        padding-top: 89px;
        margin-top: 0;
    }

    .background-container {
        background: #111929;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        height: 70%;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
    }

    .points-content-inner {
        width: 747px;
        z-index: 1;
        @media only screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    .points-up-content {
        @media only screen and (max-width: 1023px) {
            padding: 40px 21px;
            background: $primaryNavy;
        }

        .header-title {
            font-weight: 400;
            font-size: 55px;
            text-transform: uppercase;
            @media only screen and (max-width: 1440px) {
                font-size: 34px;
            }
        }
    }

    .content:deep {
        ul {
            list-style: none;
            margin-top: 21px;

            li {
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;

                &::before {
                    content: "\2022";
                    color: $burgundy;
                    font-weight: bold;
                    display: inline-block;
                    font-size: 30px;
                    width: 20px;
                    font-family: 'FiraGO';
                }
            }
        }
    }

    .image-container {
        margin-top: 55px;
        display: flex;
        @media only screen and (max-width: 1023px) {
            margin: 34px 21px 0;
            picture {
                width: 100%;
            }
        }

        .small-image-picture {
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }

        .main-image {
            width: 440.3px;
            height: 686px;
            object-fit: cover;
            margin-left: -30px;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                width: 400.3px;
                height: 550px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                width: 400.3px;
                height: 450px;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
                margin-left: unset;
                height: 500px;

            }
        }

        .small-image {
            margin-top: 50px;
            width: 361px;
            height: 562.45px;
            object-fit: cover;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                width: 300px;
                height: 550px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                width: 300.3px;
                height: 400px;
            }
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .loyalty-share-points {
            background: $primaryNavy;
        }
    }
}

</style>
